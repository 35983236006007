import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { isEmpty } from "lodash";

import { updateLocalState } from "../utils/kits";
import FormGroup from "../components/FormGroup";
import OrderLayout from "../components/OrderLayout";

const useStyles = makeStyles((theme) => ({
  search: {
    margin: "0 auto",
  },
  back: {
    textAlign: "right",
  },
}));

const Checkout = () => {
  const classes = useStyles();
  const { control, errors, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
  });

  const { action, state } = useStateMachine(updateLocalState);

  console.log("checkout", state);
  const onSubmit = (data) => {
    action(data);
    console.log("submitted data", data);
  };

  return (
    <OrderLayout>
      <Grid container spacing={3}>
        {!isEmpty(errors) && (
          <FormGroup label="Errors">
            <Grid item xs={12}>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
            </Grid>
          </FormGroup>
        )}
        <Grid item xs={12} className={classes.back}>
          <Link to="/customize">Back</Link>
        </Grid>
        <Grid item xs={12}>
          <FormGroup label="Current form data">
            <pre>{JSON.stringify(state, null, 2)}</pre>
          </FormGroup>
        </Grid>
      </Grid>
    </OrderLayout>
  );
};

export default Checkout;
